import "./styles/fonts.css";
import "./styles/variables.css";
import "./App.css";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Login from "./pages/Login/Login";
import PrivateRoute from "./routing/PrivateRoute/PrivateRoute";
import {PrimeReactProvider} from "primereact/api";
import OrderCreate from "./pages/OrderCreate/OrderCreate";
import CustomerCreate from "./pages/CustomerCreate/CustomerCreate";
import CustomerList from "./pages/CustomerList/CustomerList";
import CustomerEdit from "./pages/CustomerEdit/CustomerEdit";
import "./styles/primereact.css";
import 'primeicons/primeicons.css';
import "./styles/primereact-overrides.css";
import OrderList from "./pages/OrderList/OrderList";
import OrderEdit from "./pages/OrderEdit/OrderEdit";
import OrderShippingNote from "./pages/OrderShippingNote/OrderShippingNote";
import CloudPrintingTest from "./pages/CloudPrintingTest/CloudPrintingTest";


function App() {
    return (
        <PrimeReactProvider value={{unstyled: false, pt: {}, ptOptions: {mergeSections: true, mergeProps: true}}}>
            <Router>
                <div className="App">
                    <Routes>
                        <Route exact path="/login" element={<Login/>}></Route>
                        <Route exact path="/order/create" element={
                            <PrivateRoute>
                                <OrderCreate breadcrumb={[
                                    {label: "Παραγγελίες", url: "/order/list"},
                                    {label: "Νέα Παραγγελία", url: "/order/create"},
                                ]}/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/order/list" element={
                            <PrivateRoute>
                                <OrderList breadcrumb={[
                                    {label: "Παραγγελίες", url: "/order/list"},
                                ]}/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/order/:idOrder/edit" element={
                            <PrivateRoute>
                                <OrderEdit breadcrumb={[
                                    {label: "Παραγγελίες", url: "/order/list"},
                                    {label: "Επεξεργασία Παραγγελίας"},
                                ]}/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/order/:idOrder/shipping-note" element={
                            <PrivateRoute>
                                <OrderShippingNote/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/customer/create" element={
                            <PrivateRoute>
                                <CustomerCreate breadcrumb={[
                                    {label: "Πελάτες", url: "/customer/list"},
                                    {label: "Νέος Πελάτης", url: "/customer/create"},
                                ]}/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/customer/list" element={
                            <PrivateRoute>
                                <CustomerList breadcrumb={[
                                    {label: "Πελάτες", url: "/customer/list"},
                                ]}/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/customer/:idCustomer/edit" element={
                            <PrivateRoute>
                                <CustomerEdit breadcrumb={[
                                    {label: "Πελάτες", url: "/customer/list"},
                                    {label: "Επεξεργασία Πελάτη"},
                                ]}/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/printing-test" element={
                            <PrivateRoute>
                                <CloudPrintingTest breadcrumb={[
                                    {label: "Cloud Printing Test"}
                                ]}/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route path="*" element={<Navigate to="/login" replace/>}/>
                    </Routes>
                </div>
            </Router>
        </PrimeReactProvider>
    );
}

export default App;
