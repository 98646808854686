import MainLayout from "../../layouts/MainLayout/MainLayout";
import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {PlusIcon} from "primereact/icons/plus";
import {AiFillEdit} from "react-icons/ai";
import {TrashIcon} from "primereact/icons/trash";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {orderDelete, orderPrint, ordersGetList, orderUpdate} from "../../services/OrderService";
import {Link} from "react-router-dom";
import {Tag} from "primereact/tag";
import {SplitButton} from "primereact/splitbutton";
import {GrPrint} from "react-icons/gr";
import * as PropTypes from "prop-types";
import {MdOutlineLocalShipping} from "react-icons/md";

function Redirect(props) {
    return null;
}

Redirect.propTypes = {to: PropTypes.string};
export default function OrderList(props) {

    const toast = useRef(null);
    const searchQueryField = useRef(null);

    const [orders, setOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const [selectedOrders, setSelectedOrders] = useState([]);

    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState("id,desc");
    const [size, setSize] = useState(50);
    const [first, setFirst] = useState(0);
    const [loading, setLoading] = useState(true);

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const ordersPage = await ordersGetList(page, size, sort, searchQuery);
            setOrders(ordersPage.content);
            setTotalRecords(ordersPage.totalElements);
            setLoading(false);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατα την φόρτωση των παραγγελιών',
                life: 3000,
                position: "bottom"
            });
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [page, sort, size, searchQuery]);


    const deleteOrder = async (order) => {
        setLoading(true)
        try {
            await orderDelete(order.id);
            setLoading(false)
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά',
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: 'Διαγράφηκε',
            detail: 'H παραγγελία διαγράφηκε με επιτυχία',
            life: 3000
        });
        fetchOrders()
    }

    const markOrderAsPaid = async (order) => {
        setLoading(true)
        try {
            const orderUpdated = order;
            orderUpdated.paid = true;
            await orderUpdate(order.id, orderUpdated);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά',
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: 'Διαγράφηκε',
            detail: 'H παραγγελία ενημερώθηκε με επιτυχία',
            life: 3000
        });
        fetchOrders()
    }

    const markOrderAsCompleted = async (order) => {
        setLoading(true)
        try {
            const orderUpdated = order;
            orderUpdated.completed = true;
            await orderUpdate(order.id, orderUpdated);
            setLoading(false)
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά',
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: 'Διαγράφηκε',
            detail: 'H παραγγελία ενημερώθηκε με επιτυχία',
            life: 3000
        });
        fetchOrders()
    }
    const rePrintOrder = async (order) => {
        setLoading(true)
        try {
            await orderPrint(order.id);
            setLoading(false)
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά',
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: 'Διαγράφηκε',
            detail: 'H παραγγελία ενημερώθηκε με επιτυχία',
            life: 3000
        });
        fetchOrders()
    }

    const closeModal = () => {

    }

    const confirmDelete = (order) => {
        confirmDialog({
            message: 'Έιστε σίγουρος πως θέλετε να διαγράψετε αυτή την παραγγελία;',
            header: 'Διαγραφή Παραγγελίας',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: "Ναί",
            rejectLabel: "Όχι",
            accept: () => {
                deleteOrder(order)
            },
            reject: () => {
                closeModal()
            }
        });
    }

    const getHeader = () => {
        return <div className={"flex items-center justify-between w-full"}>
            <span>Παραγγελίες</span>
            <div className={"flex gap-4"}>
                <span className="p-input-icon-right">
                    {searchQuery == null ? <i className="pi pi-search"/> :
                        <i className="pi pi-times cursor-pointer" onClick={() => {
                            setSearchQuery(null)
                            searchQueryField.current.value = null;
                        }}/>}
                    <InputText placeholder={"Αναζήτηση..."} ref={searchQueryField} onChange={(e) => {
                        setSearchQuery(e.target.value)
                    }}/>
                </span>
                <Link to={"/order/create"}>
                    <Button icon={<PlusIcon color={"white"} className={"mr-2"}/>} label={"Νέα Παραγγελία"}/>
                </Link>
            </div>
        </div>
    }

    const getActionsButtons = (order) => {
        return <>
            <Link to={`/order/${order.id}/edit`}>
                <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={"Επεξεργασία παραγγελίας"}
                        tooltipOptions={{position: 'bottom'}} icon={<AiFillEdit color={"grey"}/>}
                />
            </Link>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    onClick={() => {
                        confirmDelete(order)
                    }}
                    tooltip={"Διαγραφή παραγγελίας"} icon={<TrashIcon color={"grey"}/>}
            />
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    onClick={() => {
                        rePrintOrder(order)
                    }}
                    tooltip={"Επανεκτύπωση παραγγελίας"} icon={<GrPrint color={"grey"}/>}
            />
            <Link to={`/order/${order.id}/shipping-note`}>
                <Button className={"bg-transparent border-0 p-1 w-auto"} icon={<MdOutlineLocalShipping color={"grey"}/>}
                        tooltipOptions={{position: 'bottom'}} tooltip={"Εκτύπωση Αποδεικτικού Αποστολής"}

                />
            </Link>
        </>
    }

    const onSort = (e) => {
        setSort(`${e.sortField},${e.sortOrder === 1 ? "desc" : "asc"}`)
    }

    const onPage = (e) => {
        setPage(e.page);
        setFirst(e.first);
        setSize(e.rows);
    }

    const confirmAction = (message, header, accept, reject = closeModal) => {
        confirmDialog({
            message: message,
            header: header,
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: "Ναί",
            rejectLabel: "Όχι",
            accept: accept,
            reject: reject
        });
    }

    const bulkOptions = [
        {
            label: 'Διαγραφή',
            icon: 'pi pi-times',
            command: () => {
                confirmAction(
                    'Έιστε σίγουρος πως θέλετε να διαγράψετε τις επιλεγμένες παραγγελίες;',
                    'Διαγραφή επιλεγμένων παραγγελιών',
                    () => {
                        selectedOrders.map((order) => {
                            deleteOrder(order)
                        })
                        setSelectedOrders([])
                    }
                )
            }
        },
        {
            label: 'Εξοφλήθηκαν',
            icon: 'pi pi-euro',
            command: () => {
                confirmAction(
                    'Έιστε σίγουρος πως θέλετε να ορίσετε τις επιλεγμένες παραγγελίες ως εξοφλημένες;',
                    'Ενημέρωση επιλεγμένων παραγγελιών',
                    () => {
                        selectedOrders.map((order) => {
                            markOrderAsPaid(order)
                        })
                        setSelectedOrders([])
                    }
                )
            }
        },
        {
            label: 'Κατασκευάστηκαν',
            icon: 'pi pi-check',
            command: () => {
                confirmAction(
                    'Έιστε σίγουρος πως θέλετε να ορίσετε τις επιλεγμένες παραγγελίες ως κατασκευασμένες;',
                    'Ενημέρωση επιλεγμένων παραγγελιών',
                    () => {
                        selectedOrders.map((order) => {
                            markOrderAsCompleted(order)
                        })
                        setSelectedOrders([])
                    }
                )
            }
        },
    ]

    const getFooter = (e) => {
        return <div className={"flex gap-4"}>
            {showBulkOptions()}
            {showExportForm()}
        </div>
    }

    const showBulkOptions = () => {
        return <SplitButton
            outlined
            disabled={selectedOrders.length === 0}
            label="Διαγραφή"
            icon="pi pi-times"
            size={"small"}
            model={bulkOptions}
            onClick={() => {
                confirmAction(
                    'Έιστε σίγουρος πως θέλετε να διαγράψετε τις επιλεγμένες παραγγελίες;',
                    'Διαγραφή επιλεγμένων παραγγελιών',
                    () => {
                        selectedOrders.map((order) => {
                            deleteOrder(order)
                        })
                        setSelectedOrders([])
                    }
                )
            }}
        />
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_Εξαγωγή_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const exportToExcel = () => {
        import('xlsx').then((xlsx) => {
            const ordersWithFlattenedCustomer = orders.map(order => ({
                ΗΜΕΡΟΜΗΝΙΑ: order.dateAdd,
                ID: `#${order.id}`,
                ΠΕΛΑΤΗΣ: `${order.customer.firstName} ${order.customer.lastName}`,
                ΠΑΡΑΓΓΕΛΙΑ: order.description,
                ΕΤΑΙΡΕΙΑ: order.customer.companyName,
                ΔΙΕΥΘΥΝΣΗ: order.customer.address,
                ΑΦΜ: order.customer.vatNumber,
                ΤΗΛΕΦΩΝΟ: order.customer.phoneNumber1,
                ΔΟΥ: order.customer.doy,
                ΤΚ: order.customer.postCode,
                ΠΟΛΗ: order.customer.city,
                ΠΟΣΟ_ΠΑΡΑΓΓΕΛΙΑΣ: order.price,
                ΕΞΟΦΛΗΘΗ: order.paid ? "ΝΑΙ" : "OXI",
                ΚΑΤΑΣΚΕΥΑΣΤΗΚΕ: order.completed ? "ΝΑΙ" : "OXI",
            }));

            const worksheet = xlsx.utils.json_to_sheet(ordersWithFlattenedCustomer);
            const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Παραγγελίες');
        });
    }

    const showExportForm = () => {
        return <Button
            outlined
            label="Εξαγωγή σε Excel"
            icon="pi pi-file-excel"
            size={"small"}
            onClick={exportToExcel}
        />
    }

    return <MainLayout breadcrumb={props.breadcrumb}>
        <Toast ref={toast} position="bottom-right"/>
        <ConfirmDialog/>
        <Panel header={getHeader()}>
            <DataTable
                value={orders} showGridlines selectionMode={'checkbox'} selection={selectedOrders}
                onSelectionChange={(e) => setSelectedOrders(e.value)}
                stripedRows className={"w-full"} size={"small"} paginator
                totalRecords={totalRecords} lazy loading={loading} onSort={onSort}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Σύνολο: {totalRecords} Παραγγελίες"
                first={first} onPage={onPage} rows={size} pageCount={5} rowsPerPageOptions={[5, 10, 25, 50]}
                emptyMessage={"Δεν βρέθηκαν παραγγελίες"}
                dataKey="id" paginatorLeft={getFooter()}
            >
                <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                <Column field="dateAdd" header="Ημερομηνία" body={(order) => {
                    if (order.dateAdd == null) {
                        return " "
                    }
                    return order.dateAdd.replace("T", " ")
                }}/>
                <Column field="id" sortable header="#" body={(order) => {
                    return <span className={"font-bold green"}>#{order.id}</span>
                }}/>
                <Column field="customer.firstName" sortable header="Όνομα Πελάτη" body={(order) => {
                    return `${order.customer.firstName} ${order.customer.lastName}`
                }}/>
                <Column field="customer.companyName" sortable header="Εταιρεία Πελάτη" body={(order) => {
                    return `${order.customer.companyName} (ΑΦΜ: ${order.customer.vatNumber})`
                }}/>
                <Column field="customer.phoneNumber1" sortable header="Τηλεφωνο"/>
                <Column field="price" header="Ποσό" body={(order) => {
                    if (order.price == null) {
                        return ""
                    }
                    return <b>{order.price.toFixed(2) + "€"}</b>
                }}/>
                <Column field="completed" header="Κατασκευάστηκε" body={(order) => {
                    return order.completed ?
                        <Tag className="mr-2" icon="pi pi-check" severity="success" value="Κατασκευάστηκε"/> : ""
                }}/>
                <Column field="paid" header="Εξοφλήθη" body={(order) => {
                    return order.paid ?
                        <Tag className="mr-2" icon="pi pi-check" severity="success" value="Εξοφλήθη"/> : ""
                }}/>
                <Column field="actions" header="Ενέργειες" body={getActionsButtons}/>
            </DataTable>
        </Panel>
    </MainLayout>;
}
