import {useRef, useState} from "react";
import Validate from "../../../../utils/Validate";
import {userLogin} from "../../../../services/UserService";
import Input from "../../../../components/UI/Input/Input";
import ErrorMessage from "../../../../components/UI/ErrorMessage/ErrorMessage";
import {ReactComponent as UserInputIcon} from "../../../../assets/UserInputIcon.svg";
import {ReactComponent as PasswordInputIcon} from "../../../../assets/PasswordInputIcon.svg";
import {Button, ButtonVariation} from "../../../../components/UI/Button/Button";


export default function LoginForm(props) {

    const usernameRef = useRef();
    const passwordRef = useRef();
    const buttonRef = useRef();

    const [errorMessage, setErrorMessage] = useState(null)

    const login = async () => {

        const username = usernameRef.current.getValue();
        const password = passwordRef.current.getValue();

        // Validation
        if (Validate.isEmpty(username)) return setErrorMessage("Παρακαλώ εισάγετε όνομα χρήστη")
        if (Validate.isEmpty(password)) return setErrorMessage("Παρακαλώ εισάγετε κωδικό")

        // open loading animation
        props.setLoadingAnimationState(true);

        // Try to login
        let responseData
        try {
            const response = await userLogin(username, password)
            responseData = response.data
        } catch (error) {
            props.setLoadingAnimationState(false);
            return setErrorMessage("Το όνομα χρήστη ή ο κωδικός δεν είναι σωστά")
        }
        if (Validate.isEmpty(responseData)) {
            props.setLoadingAnimationState(false);
            return setErrorMessage("Κάτι πήγε στραβά")
        }

        // Save tokens to localstorage
        localStorage.setItem("accessToken", responseData.accessToken)
        localStorage.setItem("refreshToken", responseData.refreshToken)

        // redirect
        window.location.href = "/order/list"

        if(responseData.user.company.name === "Dinerly"){
            window.location.href = "/printing-test"
        }else{
            window.location.href = "/order/list"
        }
    }

    return (
        <div className={"w-full"}>

            <h1 className={"text-white text-4xl font-semibold text-center mb-16"}>Σύνδεση</h1>

            <Input
                ref={usernameRef}
                IconComponent={UserInputIcon}
                label={"Όνομα Χρήστη"}
                type={"text"}
            />

            <Input
                ref={passwordRef}
                IconComponent={PasswordInputIcon}
                label={"Κωδικός"}
                type={"password"}
            />

            <ErrorMessage
                message={errorMessage}
            />

            <Button
                variation={ButtonVariation.WHITE}
                onClick={login}
                ref={buttonRef}
                text={"Σύνδεση"}
            />
        </div>
    )
}
