import MainLayout from "../../layouts/MainLayout/MainLayout";
import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FormControl from "../../components/UI/FormControl";
import {customerCreate} from "../../services/CustomerService";

export default function CustomerCreate(props) {
    const formSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData(e.target)
        const data = {}
        formData.forEach((value, key) => (data[key] = value))

        try {
            const response = await customerCreate(data);
        } catch (error) {
            alert("error");
            return
        }

        window.location.href = "/customer/list"
    }


    return <MainLayout breadcrumb={props.breadcrumb}>
        <Panel header={"Νέος Πελάτης"}>
            <form onSubmit={formSubmit}>
                <span className={"font-bold mb-4 block"}>ΥΠΕΥΘΥΝΟΣ ΕΠΙΚΟΙΝΩΝΙΑΣ</span>
                <div className={"flex gap-4"}>
                    <FormControl label={"Όνομα"} required={true}>
                        <InputText placeholder={"Όνομα"} name={"firstName"}/>
                    </FormControl>
                    <FormControl label={"Επώνυμο"} required={true}>
                        <InputText placeholder={"Επώνυμο"} name={"lastName"}/>
                    </FormControl>
                </div>
                <div className={"flex gap-4"}>
                    <FormControl label={"Τηλέφωνο Επικοινωνίας Κινητό"} required={true}>
                        <InputText placeholder={"Τηλέφωνο Επικοινωνίας Κινητό"} name={"phoneNumber1"}/>
                    </FormControl>
                    <FormControl label={"Τηλέφωνο Επικοινωνίας Σταθερό"}>
                        <InputText placeholder={"Τηλέφωνο Επικοινωνίας Σταθερό"} name={"phoneNumber2"}/>
                    </FormControl>
                </div>
                <span className={"font-bold mb-4 block"}>ΕΤΑΙΡΕΙΑ</span>
                <div className={"flex gap-4"}>
                    <FormControl label={"Επωνυμία"}>
                        <InputText placeholder={"Επωνυμία Εταιρείας"} name={"companyName"}/>
                    </FormControl>
                    <FormControl label={"Α.Φ.Μ."}>
                        <InputText placeholder={"Α.Φ.Μ."} name={"vatNumber"}/>
                    </FormControl>
                    <FormControl label={"Δ.Ο.Υ."}>
                        <InputText placeholder={"Δ.Ο.Υ."} name={"doy"}/>
                    </FormControl>
                </div>
                <div className={"flex gap-4"}>
                    <FormControl label={"Διεύθυνση"}>
                        <InputText placeholder={"Διεύθυνση"} name={"address"}/>
                    </FormControl>
                    <FormControl label={"Τ.Κ."}>
                        <InputText placeholder={"Τ.Κ."} name={"postCode"}/>
                    </FormControl>
                    <FormControl label={"Πόλη"}>
                        <InputText placeholder={"Πόλη"} name={"city"}/>
                    </FormControl>
                </div>
                <div className={"flex justify-end"}>
                    <Button label="Δημιουργία Πελάτη"/>
                </div>
            </form>
        </Panel>
    </MainLayout>;
}
