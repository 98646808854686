import axios from "axios";
import {refreshToken} from "../services/AuthService";

export const callApiWithAuthentication = async (apiCall) => {
    try {
        return await makeCall(apiCall)
    } catch (error) {
        if (error.response.status === 403) {
            await refreshToken()
            return await makeCall(apiCall)
        }
    }
}

const makeCall = async (apiCall) => {
    apiCall.headers = {
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
    }
    const response = await axios(apiCall);
    return response.data;
}
