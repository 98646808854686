import styles from "./MainLayout.module.css"
import {BreadCrumb} from "primereact/breadcrumb";
import {HiHome} from "react-icons/hi";
import Navbar from "../../components/NavBar/Navbar";

export default function MainLayout(props) {
    return (
        <>
            <div className={"w-full flex"}>
                <Navbar/>
                <div className={`bg-zinc-200 h-screen overflow-auto ${styles.mainContainer}`}>
                    <div className={"p-10"}>
                        <BreadCrumb className={"mb-10 bg-transparent p-0"} model={props.breadcrumb}
                                    home={{icon: <HiHome color={"#7C7C7C"}/>, url: "/"}}/>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}
