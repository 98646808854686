import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";

export async function customerCreate(requestBody) {
    try {
        const response = await callApiWithAuthentication({
            method: "post",
            url: `${window.API_URL}/customers/`,
            data: requestBody
        });
        return response
    } catch (error) {
        throw error;
    }
}

export async function customerUpdate(idCustomer, requestBody) {

    try {
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/customers/${idCustomer}/`,
            data: requestBody
        });
        return response
    } catch (error) {
        throw error;
    }
}

export const customersGetList = async (page=0, size=999999, sort="id,desc") => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/customers/?page=${page}&size=${size}&sort=${sort}`
        });
        return response
    } catch (error) {
        throw error;
    }
};


export const customersGetDetails = async (id) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/customers/${id}/`
        });
        return response
    } catch (error) {
        throw error;
    }
};
export const customerDelete = async (id) => {
    try {
        const response = await callApiWithAuthentication({
            method: "delete",
            url: `${window.API_URL}/customers/${id}/`
        });
        return response
    } catch (error) {
        throw error;
    }
};
