import React, {useEffect, useState} from "react";
import styles from "./Navbar.module.css";
import NavbarLink from "../NavbarLink/NavbarLink.js";
import {ReactComponent as LogoSvg} from "../../assets/LogoGreen.svg";
import {ReactComponent as OrdersSvg} from "../../assets/Orders.svg";
import {ReactComponent as OrdersActiveSvg} from "../../assets/OrdersGreen.svg";
import {ReactComponent as CustomersSvg} from "../../assets/Customers.svg";
import {ReactComponent as CustomersActiveSvg} from "../../assets/CustomersGreen.svg";
import {ReactComponent as RepairSvg} from "../../assets/Repair.svg";
import {ReactComponent as RepairActiveSvg} from "../../assets/RepairGreen.svg";
import {MdLogout} from "react-icons/md";
import {urlContains} from "../../utils/urlContains";
import {IoReceiptOutline} from "react-icons/io5";
import {userGetDetails} from "../../services/UserService";

function Navbar() {

    const logout = () => {
        localStorage.setItem("accessToken", null)
        localStorage.setItem("refreshToken", null)
        window.location.href = "/login"
    }

    return (
        <div className={styles.navbar}>
            <div>
                <div className={styles.logo}>
                    <LogoSvg/>
                </div>

                <div className={styles.linkList}>
                    <span className={"font-bold block mb-4"}>ΜΕΝΟΥ</span>
                    <NavbarLink
                        linkTo="/order/list"
                        icon={<OrdersSvg/>}
                        iconActive={<OrdersActiveSvg/>}
                        label="Παραγγελίες"
                        active={urlContains("order")}
                    />
                    <NavbarLink
                        linkTo="/customer/list"
                        icon={<CustomersSvg/>}
                        iconActive={<CustomersActiveSvg/>}
                        label="Πελάτες"
                        active={urlContains("customer")}
                    />
                    {/*<NavbarLink
                        linkTo="/repair/list"
                        icon={<RepairSvg/>}
                        iconActive={<RepairActiveSvg/>}
                        label="Επισκευές"
                        active={urlContains("repair")}
                    />*/}
                </div>
            </div>

            <button className={"flex items-center gap-4 cursor-pointer p-2"} onClick={logout}>
                <MdLogout size={25} color={"#7C7C7C"}/>
                <span className={styles.label}>Αποσύνδεση</span>
            </button>
        </div>
    );
}

export default Navbar;
