import React, {useImperativeHandle, useRef, useState} from "react";
import styles from './Input.module.css';
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";


const Input = React.forwardRef((props, ref) => {

    // exposing the inputs value to the parents ref.
    const inputRef = useRef();

    function getValue() {
        return inputRef.current.value
    }

    function setFocusOnField() {
        inputRef.current.focus()
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: getValue
        }
    })

    // type == "password" visibility
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const changePasswordVisibility = () => {
        inputRef.current.type = inputRef.current.type === "text" ? "password" : "text";
        setIsPasswordVisible(!isPasswordVisible)
    }

    return (
        <div className={styles.inputContainer} onClick={setFocusOnField}>

            <div className={styles.iconContainer} onClick={setFocusOnField}>
                <props.IconComponent className={styles.icon} onClick={setFocusOnField}/>
            </div>

            <div className={styles.inputInnerContainer} onClick={setFocusOnField}>
                <input
                    className={styles.input}
                    ref={inputRef}
                    placeholder={" "}
                    type={props.type}
                />
                <label
                    className={styles.label}
                    onClick={setFocusOnField}
                >
                    {props.label}
                </label>
            </div>

            {props.type === "password" &&
                <button className={styles.passwordVisibilityButton} onClick={changePasswordVisibility}>
                    {isPasswordVisible ?
                        <AiFillEye size={25} className={"fill-white"}/> :
                        <AiFillEyeInvisible size={25} className={"fill-white"}/>
                    }
                </button>
            }
        </div>
    );
})

export default Input
