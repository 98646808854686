import React, {useRef, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';
import {orderGetDetails} from '../../services/OrderService';
import LoadingAnimation from '../../components/UI/LoadingAnimation/LoadingAnimation';
import {userGetDetails} from '../../services/UserService';
import {loadProtectedImage} from '../../services/ImageService';

const OrderShippingNote = () => {
    const {idOrder} = useParams();
    const [order, setOrder] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const componentRef = useRef();

    const fetchData = async () => {
        setLoading(true)
        try {
            const [userDetails, orderDetails] = await Promise.all([
                userGetDetails(),
                orderGetDetails(idOrder),
            ]);
            setImageUrl(await loadProtectedImage(userDetails.image.url));
            // Set the results after both promises have resolved
            setUser(userDetails);
            setOrder(orderDetails);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch customer data:', error);
        }
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (order) {
            handlePrint();
        }
    }, [order]);

    const OrderShippingHeaderText = (params) => {
        return <b style={{
            marginBottom: '10px',
            fontSize: "16px",
            display: "block",
            borderBottom: "1px solid black "
        }}>{params.text}</b>
    }

    return (
        <div>
            <div ref={componentRef} className="mt4" style={{
                fontSize: "13px"
            }}>
                <div style={{padding: "20px",}}>
                    <OrderShippingHeaderText text={`Συνοδευτικό Αποστολής Παραγγελίας #${order != null && order.id}`}/>

                    <table style={{marginBottom: '10px', width: '100%'}}>
                        <tr>
                            <td style={{width: "100px", paddingRight: "10"}}>
                                {imageUrl != null && <img src={imageUrl} alt="User"/>}
                            </td>
                            <td>
                                <p style={{margin: '0 0 0 10px'}}>Αποστολέας</p>
                                <b style={{marginLeft: '10px', fontSize: "20px"}}>ΚΑΡΑΜΠΕΛΑΣ ΣΩΤΗΡΙΟΣ </b>
                            </td>
                        </tr>
                    </table>


                    <OrderShippingHeaderText text={`Παραλήπτης Δέματος`}/>

                    <div style={{border: "1px solid black", padding: "10px", marginBottom: "15px",}}>
                        {order != null && <>
                            <b style={{
                                marginBottom: "5px",
                            }}>{order.customer.firstName} {order.customer.lastName}</b>
                            <p>{order.customer.companyName}</p>
                            <p>{order.customer.address}</p>
                            <p>ΤΗΛ: {order.customer.phoneNumber1} {order.customer.phoneNumber2}</p>
                        </>}
                    </div>

                    <OrderShippingHeaderText text={`Περιγραφή Παραγγελίας`}/>

                    <div style={{border: "1px solid black", padding: "10px", marginBottom: "15px"}}>
                        <div style={{maxHeight: "150px", overflow: "hidden"}}>
                            <span style={{whiteSpace: "pre-wrap"}}>{order != null && order.description}</span>
                        </div>
                    </div>
                </div>
            </div>
            <LoadingAnimation state={loading}/>
        </div>
    );
}

export default OrderShippingNote;
